import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`I nostri prodotti`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`I seguenti tranciati sono pronti in consegna negli spessori di 0,6 mm, 1 mm e 2 mm:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Noce nazionale`}</li>
          <li parentName="ul">{`Ciliegio`}</li>
          <li parentName="ul">{`Acero argentato`}</li>
          <li parentName="ul">{`Acero rosso`}</li>
          <li parentName="ul">{`Castagno`}</li>
          <li parentName="ul">{`Rovere`}</li>
          <li parentName="ul">{`Frassino europeo`}</li>
          <li parentName="ul">{`Cedro`}</li>
          <li parentName="ul">{`Acacia`}</li>
          <li parentName="ul">{`Radica`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Tranciati di altre essenze e spessori sono disponibili su richiesta. `}<a parentName="p" {...{
            "href": "/it/contatti"
          }}>{`Contattateci`}</a>{` per informazioni su altri materiali o per prodotti e servizi di tranciatura personalizzati.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      